<template>
	<div class="article-details-box">
		<article>
			<!--顶部封面-->
			<header>
				<div class="upper-header">
					<div class="mini-title">article</div>
					<div class="date-since">
						<p><span class="date-value" id="sinceData">30分钟前</span></p>
						<svg t="1659875517934" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1496" width="20" height="20"><path fill="currentColor" d="M511.913993 63.989249c-247.012263 0-447.924744 200.912481-447.924744 447.924744s200.912481 447.924744 447.924744 447.924744 447.924744-200.912481 447.924744-447.924744S758.926256 63.989249 511.913993 63.989249zM671.199059 575.903242 480.263397 575.903242c-17.717453 0-32.166639-14.449185-32.166639-32.166639L448.096758 289.15572c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 222.930287 159.285066 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S688.916513 575.903242 671.199059 575.903242z" p-id="1497"></path></svg>
					</div>
				</div>
				<div class="lower-header">
					<div class="tags-container">
						<svg t="1659875243463" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1346" width="20" height="20"><path d="M995.126867 635.046667l-360.08 360.08a53.333333 53.333333 0 0 1-71.333334 3.68l356.22-356.22a64 64 0 0 0 0-90.506667L495.8402 128h45.573333a52.986667 52.986667 0 0 1 37.713334 15.62l416 416a53.4 53.4 0 0 1 0 75.426667z m-128-75.426667l-416-416A52.986667 52.986667 0 0 0 413.413533 128H338.846867a361.706667 361.706667 0 0 1 2.486666 42.666667c0 42.713333-7.266667 83.2-20.473333 114-5.146667 12-10.746667 21.546667-16.48 29.08A85.286667 85.286667 0 1 1 256.0002 298.666667c6 0 16.573333-9.64 25.646667-30.806667C292.4602 242.62 298.666867 207.193333 298.666867 170.666667a314.76 314.76 0 0 0-2.873334-42.666667H216.206867c2.82-20.533333 7.653333-39.366667 14.146666-54.526667C239.426867 52.306667 250.0002 42.666667 256.0002 42.666667s16.573333 9.64 25.646667 30.806666c6.493333 15.16 11.333333 34 14.146666 54.526667h43.053334c-3.166667-26.56-9.286667-51.046667-18-71.333333C299.733533 7.333333 271.153533 0 256.0002 0s-43.733333 7.333333-64.86 56.666667c-8.666667 20.286667-14.82 44.773333-18 71.333333H53.333533a53.393333 53.393333 0 0 0-53.333333 53.333333v360.08a52.986667 52.986667 0 0 0 15.62 37.713334l416 416a53.333333 53.333333 0 0 0 75.426667 0l360.08-360.08a53.4 53.4 0 0 0 0-75.426667z" fill="currentColor" p-id="1347"></path></svg>
						<span>未来</span><span>过去</span>
					</div>
					<h1 class="title">多模态交互</h1>
					<p class="subtitle">在人工智能的浩瀚星空中，Agent AI如同璀璨星辰，以其独特的魅力引领着人机交互的新纪元。随着技术的飞速发展，智能体不再局限于单一的数据输入方式，而是能够跨越图像、语音、文本等多种模态的界限，实现与人类的全方位、深层次互动</p>
				</div>
			</header>
			
			<!--文章数据-->
			<section class="summary">
				<div class="summary-item">
					<h5 class="item-title">阅读时间</h5>
					<p class="item-text"><span class="item-data">20</span> 分钟</p>
				</div>
				<div class="summary-item">
					<h5 class="item-title">浏览量</h5>
					<p class="item-text"><span class="item-data">24050</span> 阅读</p>
				</div>
				<div class="summary-item">
					<h5 class="item-title">发布时间</h5>
					<p class="item-text"><span class="item-data">12月 25, 2024</span></p>
				</div>
			</section>
			
			<!--文章详细内容-->
			<section class="main-article">
				<h4>一、多模态交互：从单一到多元的跨越</h4>
				<p>传统的人机交互往往依赖于单一的输入方式，如键盘打字、鼠标点击或语音指令。</p>
				<p>然而，在现实世界中，人们交流的方式是多种多样的，包括面部表情、肢体语言、语音语调等。</p>
				<p>多模态交互正是旨在模拟这种自然、丰富的交流方式，使智能体能够同时处理并理解来自不同渠道的信息，从而做出更加准确、人性化的响应。</p>
				<div class="gallery">
					<div class="image-item image-1" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/2.jpeg') + ')'}"></div>
					<div class="image-item image-2" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/3.jpeg') + ')'}"></div>
					<div class="image-item image-3" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/4.jpeg') + ')'}"></div>
					<div class="image-item image-4" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/5.jpeg') + ')'}"></div>
					<div class="image-item image-5" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/6.jpeg') + ')'}"></div>
				</div>
				
				<h4>二、Agent AI的技术基石</h4>
				<p>深度学习：作为Agent AI的核心驱动力，深度学习使得智能体能够从海量数据中自动学习并提取特征，实现对图像、语音、文本等复杂数据的高效处理与理解。</p>
				<p>自然语言处理（NLP）：NLP技术的进步使得智能体能够理解和生成人类语言，实现流畅的文本对话。同时，结合情感分析、语义理解等技术，智能体还能感知并回应人类的情感与意图。</p>
				<p>计算机视觉：通过图像识别、目标检测等技术，智能体能够解析视觉信息，理解场景中的物体、人物及其关系，为多模态交互提供视觉维度的支持。</p>
				<blockquote class="pullquote">语音识别与合成：高精度的语音识别技术让智能体能够准确捕捉人类语音，而流畅的语音合成则使智能体的回答更加自然、亲切。 &mdash;  AI</blockquote>
				
				
				<h4>三、Agent AI的应用实践</h4>
				<p>智能家居：在家庭环境中，Agent AI可以作为智能家居的控制中心，通过语音、手势或面部识别等方式接收用户指令，调节灯光、温度、安防等设备，创造舒适便捷的居住环境。</p>
				<p>医疗健康：在医疗领域，Agent AI能够辅助医生进行病情诊断、制定治疗方案，并通过多模态交互与患者沟通，提供心理支持与健康指导。</p>
				<p>教育娱乐：在教育方面，Agent AI可以作为个性化学习伴侣，根据学生的学习习惯与进度提供定制化教学方案；在娱乐领域，则能化身虚拟角色，与用户进行互动游戏、聊天解闷。</p>				 
				
				<h4>四、未来展望</h4>
				<p>随着技术的不断进步与融合，Agent AI在多模态交互领域的潜力将愈发巨大。</p>
				<p>未来，我们有望看到更加智能、更加人性化的智能体，它们不仅能够理解并回应人类的各种需求与情感，还能主动预测并满足我们的潜在需求，成为我们生活中不可或缺的伙伴。</p>
				<p>Agent AI在多模态交互领域的探索与实践，正逐步揭开人机交互新篇章的序幕。让我们共同期待这个充满无限可能的未来吧！</p>
				<p>......</p>
				
			</section>
		</article>
	</div>
	
</template>

<script>
export default {
	name: "ArticleDetailsView",
	data(){
		return {
		}
	},
	computed(){
	
	}
}
</script>

<style scoped lang="scss">


.article-details-box {
	display: flex;
	justify-content: center;
	width: 100%;
	//height: calc(max(100vh, 400px));
	background-color: rgba(16 18 27 / 10%);
	padding: 20px 40px;
	color: white;
	font-family: "Plus Jakarta Sans", sans-serif;
	overflow: auto;
	
	article {
		width: 100%;
		//max-width: 1000px;
		
		header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			min-height: 400px;
			padding: 50px;
			border-radius: 16px;
			color: #fff;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url("../../assets/images/article/1.jpeg");
			
			.upper-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 20px;
				
				.mini-title {
					font-size: 1.125rem;
					font-weight: bold;
					letter-spacing: 0.4rem;
					text-transform: uppercase;
					opacity: 0.9;
				}
				
				.date-since {
					display: flex;
					align-items: center;
					opacity: 0.5;
					font-size: 0.875rem;
					
					.date-value {
						display: inline-block;
						padding-bottom: 2px;
					}
					
					svg {
						width: 20px;
						margin-left: 10px;
					}
				}
			}
			
			.lower-header {
				padding-top: 50px;
				
				.tags-container {
					display: flex;
					align-items: center;
					opacity: 0.75;
					margin-bottom: 12px;
					
					& > span:not(:nth-child(2))::before {
						content: ", ";
					}
					
					svg {
						width: 20px;
						margin-right: 10px;
					}
					
					span {
						font-size: 0.875rem;
					}
				}
				
				.title {
					margin: 20px 0;
					font-size: 3rem;
					font-weight: bold;
					opacity: 0.9;
				}
				
				.subtitle {
					width: 50%;
					margin-top: 10px;
					opacity: 0.75;
					line-height: 1.75;
				}
			}
		}
	}
	
	.summary {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
		padding: 30px 50px;
		border-radius: 16px;
		box-shadow: 0 0 0 1px #f2f2f2;
		
		.summary-item {
			width: 100%;
			padding-right: 20px;
			
			.item-title {
				color: #eae9e9;
				letter-spacing: 2px;
			}
			
			.item-text {
				margin-top: 10px;
				font-size: 1.5rem;
			}
			*{
				margin: 0;
			}
		}
	}
	
	.main-article {
		width: 100%;
		margin-top: 50px;
		padding-bottom: 50px;
		line-height: 1.75;
		
		h4 {
			margin-top: 60px;
			margin-bottom: 20px;
			font-size: 1.25em;
		}
		
		p {
			margin-bottom: 20px;
			color: #777;
			color: white;
			font-size: 1.125em;
		}
		
		blockquote {
			position: relative;
			margin: 40px 0;
			padding: 40px;
			//改为渐变背景
			background-color: #b6b6b6;
			background-color: rgba(186, 191, 217, 0.4);
			
			border-radius: 16px;
			
			&::before {
				content: url("https://icons.craftwork.design/static/media/QuotesFill.f65b03951f44e212816420b00909f4df.svg");
				position: absolute;
				top: -10px;
				left: 0px;
				transform: scale(2);
				opacity: 0.1;
			}
			
			&::after {
				content: url("https://icons.craftwork.design/static/media/QuotesFill.f65b03951f44e212816420b00909f4df.svg");
				position: absolute;
				bottom: -10px;
				right: 0px;
				transform: scale(2) rotate(180deg);
				opacity: 0.1;
			}
		}
		
		.gallery {
			display: grid;
			gap: 20px;
			grid-template-areas:
				"img1 img1 img2 img3"
				"img1 img1 img4 img5";
			min-height: 400px;
			margin-top: 40px;
			
			.image-item {
				min-height: 200px;
				background-color: #eee;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 8px;
				transition: 250ms;
				
				&:hover {
					opacity: 0.9;
					cursor: pointer;
				}
			}
			
			.image-1 {
				grid-area: img1;
			}
			.image-2 {
				grid-area: img2;
			}
			.image-3 {
				grid-area: img3;
			}
			.image-4 {
				grid-area: img4;
			}
			.image-5 {
				grid-area: img5;
			}
			
		}
	}
}

@media screen and (max-width: 1024px) {
	.article-details-box {
		padding: 20px;
		
		article header .lower-header .subtitle {
			width: 100%;
		}
	}
}

@media screen and (max-width: 768px) {
	.article-details-box article {
		header .lower-header .title {
			word-break: break-all;
		}
		
		.summary {
			flex-direction: column;
			
			.summary-item:not(:last-child) {
				padding-bottom: 30px;
			}
		}
		
		.main-article {
			.gallery {
				grid-template-areas:
					"img1 img1"
					"img1 img1"
					"img2 img3"
					"img4 img5";
			}
		}
	}
}

@media screen and (max-width: 425px) {
	.article-details-box {
		padding: 20px;
	}
	
	.article-details-box article header .upper-header .date-since {
		display: none;
	}
}


</style>