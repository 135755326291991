<template>
	<div class="record">
		<h1>记录线</h1>
		<div class="record-boxes">
			<div class="boxes">
				<div class="box" v-for="item in list" :key="item.id">
					<!--<p class="icon"></p>-->
					<h3>{{item.title}}</h3>
					<p class="time">{{item.time}}</p>
					{{item.content}}
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data(){
		return {
			list:[
				{id:11,title:'公司',time:'2024-08-02',content:'创立'},
				{id:10,title:'平台备案',time:'2024-08-12',content:'初审'},
				{id:9,title:'平台备案',time:'2024-08-13',content:'初审拒绝'},
				{id:8,title:'平台备案',time:'2024-08-14',content:'初审通过'},
				{id:7,title:'管局审核',time:'2024-08-14',content:'进入第二阶段审核'},
				{id:6,title:'管局审核',time:'2024-08-23',content:'审核通过'},
		
			]
		}
	}
	
}
</script>

<style lang="scss" scoped>
	
	@import url("https://fonts.googleapis.com/css?family=Lato:200,300,400,400i,700");
	@import url("https://fonts.googleapis.com/css?family=Merriweather:200,400,400i,700");
	
	.record {
		background-color: #181818;
		background-color: rgba(16 18 27 / 10%);
		color: #d8d8d8;
		font-family: Lato, sans-serif;
		padding: 20px 40px;
		width: 100%;
		overflow: auto;
		
		h1{
			font-size: 2.5em;
			text-transform: uppercase;
			letter-spacing: 10px;
			text-align: center;
			font-family: Lora, serif;
			font-weight: 200;
			
			&::before {
				content: '─';
				margin-right: 0.6em;
				color: #686868;
				color: #d8d8d8;
			}
			&::after {
				content: '─';
				margin-left: 0.6em;
				color: #686868;
				color: #d8d8d8;
			}
		}
		
		.record-boxes{
			width: 100%;
			
			.boxes {
				margin-top: 50px;
				display: flex;
				flex-wrap: wrap;
				margin-right: -30px;
				
				
				.box {
					width: calc(33.333333% - 30px);
					margin-right: 30px;
					font-size: 1em;
					line-height: 1.5em;
					font-weight: 200;
					padding: 0 1vw 1vw;
					margin-bottom: 40px;
					border-right: 2px;
					border-left: 0;
					border-style: solid;
					//text-align: left;
					//border-image: linear-gradient(to bottom, #ccc, #555 66%, #181818) 1 100%;
					//border-image: linear-gradient(to bottom, #181818, #eee, #181818) 1 100%;
					border-image: linear-gradient(to bottom, #10121B19, white, #10121B19) 1 100%;
					
					
					h2 {
						font-size: 1.3em;
						//text-transform: uppercase;
						letter-spacing: 4px;
						font-weight: 200;
						line-height: 0.5em;
						color: #f2f2f2;
					}
					.time {
						font-style: italic;
						color: #9f9f9f;
						margin-bottom: 0.5em;
						letter-spacing: 1px;
						font-family: Lora, serif;
						//background-color: red;
					}
					
					
					&:hover{
						transform: scale(1.02);
						//background-color: rgba(16 18 27 / 20%);
					}
					
					@media screen and (min-width: 1000px){
						
						// 只选取三的倍数的元素
						&:nth-child(3n){
							border-right: 0;
						}
					}
					
					@media screen and (max-width: 1000px) {
						width: calc(50% - 30px);
						
						&:nth-child(2n){
							border-right: 0;
						}
						
					}
					@media screen and (max-width: 565px) {
						width: calc(100% - 30px);
						padding: 0 5vw 1vw;
						border-right: 0;
						border-left: 2px;
						border-style: solid;
						border-image: linear-gradient(to bottom, #181818, #eee, #181818) 1 100%;
						
					}
				}
				
				
			}
		}
	}
	
	
</style>