<template>
  <div class="news-card-box">
    <div class="news-card">
      <div class="example-1 card">
        <div class="wrapper" :style="backgroundStyle">
          <div class="date">
            <span class="day">12</span>
            <span class="month">八月</span>
            <span class="year">2022</span>
          </div>
          <div class="data">
            <div class="content">
              <span class="author">AI萌宠</span>
              <h1 class="title"><a href="#">卡皮巴拉</a></h1>
              <p class="text">
                风靡网络的歌曲Capybara，让“卡皮巴拉”也成了大家的新宠，其萌萌又冷静的样子，深受大家的喜爱。但其实，爱群居的它并不是“社牛”，原来是个社恐的“i人”，性情温和
              </p>
              <label for="show-menu" class="menu-button"><span></span></label>
            </div>
            <input type="checkbox" id="show-menu" />
            <ul class="menu-content">
              <li>
                <a href="#" class="fa fa-bookmark-o">
                  <svg
                    t="1660302111666"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1369"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M835 959.2c-15.2 0-30.9-4.5-46-13.4L513.5 783.5 235 946c-28.3 16.2-58.7 17.3-83.4 3-25.7-14.9-40.4-43.6-40.4-78.8V174.5c0-60.5 47.2-109.7 105.2-109.7h592.1c26.9 0 52.5 10.9 72.1 30.7 20.7 21 32.5 50.2 32.2 80.2v694.6c0 34.9-15.3 64.6-41 79.2-11.4 6.4-23.9 9.7-36.8 9.7zM513.7 714l305.9 180.2c8.8 5.2 17 6.4 22.4 3.2 6.7-3.8 10.8-14 10.8-27.1V175.2c0.1-14.1-5.3-27.8-14.9-37.5-8.2-8.3-18.7-12.9-29.4-12.9H216.4c-24.9 0-45.2 22.3-45.2 49.7v695.7c0 13 3.9 23.1 10.5 26.9 5.7 3.3 14.2 2.1 23.4-3.1l308.6-180z"
                      fill="#ffffff"
                      p-id="1370"
                      data-spm-anchor-id="a313x.7781069.0.i2"
                      class="selected"
                    ></path>
                    <path
                      d="M314.2 283.2h395.6c13.7 0 24.7 11.1 24.7 24.7 0 13.7-11.1 24.7-24.7 24.7H314.2c-13.7 0-24.7-11.1-24.7-24.7s11-24.7 24.7-24.7z"
                      fill="#ffffff"
                      p-id="1371"
                      data-spm-anchor-id="a313x.7781069.0.i3"
                      class="selected"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="fa fa-heart-o">
                  <svg
                    t="1660302178973"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1552"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z m0 63.146667c-41.44 0-70.261333 15.189333-116.96 55.04-2.165333 1.845333-14.4 12.373333-17.941334 15.381333a32.32 32.32 0 0 1-41.770666 0c-3.541333-3.018667-15.776-13.536-17.941334-15.381333-46.698667-39.850667-75.52-55.04-116.96-55.04C230.186667 180.48 149.333333 281.258667 149.333333 426.698667 149.333333 537.6 262.858667 675.242667 493.632 834.826667a32.352 32.352 0 0 0 36.736 0C761.141333 675.253333 874.666667 537.6 874.666667 426.698667c0-145.44-80.853333-246.218667-206.88-246.218667z"
                      p-id="1553"
                      data-spm-anchor-id="a313x.7781069.0.i8"
                      class="selected"
                      fill="#ffffff"
                    ></path>
                  </svg>
                  <span>999+</span></a
                >
              </li>
              <li>
                <a href="#" class="fa fa-comment-o">
                  <svg
                    t="1660302245826"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1736"
                    data-spm-anchor-id="a313x.7781069.0.i13"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M157.568 751.296c-11.008-18.688-18.219-31.221-21.803-37.91A424.885 424.885 0 0 1 85.333 512c0-235.637 191.03-426.667 426.667-426.667S938.667 276.363 938.667 512 747.637 938.667 512 938.667a424.779 424.779 0 0 1-219.125-60.502A2786.56 2786.56 0 0 0 272.82 866.4l-104.405 28.48c-23.893 6.507-45.803-15.413-39.285-39.296l28.437-104.288z m65.301 3.787l-17.258 63.306 63.306-17.258a32 32 0 0 1 24.523 3.21 4515.84 4515.84 0 0 1 32.352 18.944A360.79 360.79 0 0 0 512 874.667c200.299 0 362.667-162.368 362.667-362.667S712.299 149.333 512 149.333 149.333 311.701 149.333 512c0 60.587 14.848 118.955 42.827 171.136 3.712 6.912 12.928 22.827 27.37 47.232a32 32 0 0 1 3.34 24.715z m145.995-70.774a32 32 0 1 1 40.917-49.205A159.19 159.19 0 0 0 512 672c37.888 0 73.675-13.173 102.187-36.885a32 32 0 0 1 40.917 49.216A223.179 223.179 0 0 1 512 736a223.179 223.179 0 0 1-143.136-51.69z"
                      p-id="1737"
                      data-spm-anchor-id="a313x.7781069.0.i14"
                      class="selected"
                      fill="#ffffff"
                    ></path>
                  </svg>
                  <span>99+</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="example-2 card">
        <div class="wrapper">
          <div class="header">
            <div class="date">
              <span class="day">12</span>
              <span class="month">八月</span>
              <span class="year">2022</span>
            </div>
            <ul class="menu-content">
              <li>
                <a href="#" class="fa fa-bookmark-o">
                  <svg
                    t="1660302111666"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1369"
                    width="18"
                    height="18"
                  >
                    <path
                      d="M835 959.2c-15.2 0-30.9-4.5-46-13.4L513.5 783.5 235 946c-28.3 16.2-58.7 17.3-83.4 3-25.7-14.9-40.4-43.6-40.4-78.8V174.5c0-60.5 47.2-109.7 105.2-109.7h592.1c26.9 0 52.5 10.9 72.1 30.7 20.7 21 32.5 50.2 32.2 80.2v694.6c0 34.9-15.3 64.6-41 79.2-11.4 6.4-23.9 9.7-36.8 9.7zM513.7 714l305.9 180.2c8.8 5.2 17 6.4 22.4 3.2 6.7-3.8 10.8-14 10.8-27.1V175.2c0.1-14.1-5.3-27.8-14.9-37.5-8.2-8.3-18.7-12.9-29.4-12.9H216.4c-24.9 0-45.2 22.3-45.2 49.7v695.7c0 13 3.9 23.1 10.5 26.9 5.7 3.3 14.2 2.1 23.4-3.1l308.6-180z"
                      fill="#ffffff"
                      p-id="1370"
                      data-spm-anchor-id="a313x.7781069.0.i2"
                      class="selected"
                    ></path>
                    <path
                      d="M314.2 283.2h395.6c13.7 0 24.7 11.1 24.7 24.7 0 13.7-11.1 24.7-24.7 24.7H314.2c-13.7 0-24.7-11.1-24.7-24.7s11-24.7 24.7-24.7z"
                      fill="#ffffff"
                      p-id="1371"
                      data-spm-anchor-id="a313x.7781069.0.i3"
                      class="selected"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="fa fa-heart-o">
                  <svg
                    t="1660302178973"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1552"
                    width="18"
                    height="18"
                  >
                    <path
                      d="M667.786667 117.333333C832.864 117.333333 938.666667 249.706667 938.666667 427.861333c0 138.250667-125.098667 290.506667-371.573334 461.589334a96.768 96.768 0 0 1-110.186666 0C210.432 718.368 85.333333 566.112 85.333333 427.861333 85.333333 249.706667 191.136 117.333333 356.213333 117.333333c59.616 0 100.053333 20.832 155.786667 68.096C567.744 138.176 608.170667 117.333333 667.786667 117.333333z m0 63.146667c-41.44 0-70.261333 15.189333-116.96 55.04-2.165333 1.845333-14.4 12.373333-17.941334 15.381333a32.32 32.32 0 0 1-41.770666 0c-3.541333-3.018667-15.776-13.536-17.941334-15.381333-46.698667-39.850667-75.52-55.04-116.96-55.04C230.186667 180.48 149.333333 281.258667 149.333333 426.698667 149.333333 537.6 262.858667 675.242667 493.632 834.826667a32.352 32.352 0 0 0 36.736 0C761.141333 675.253333 874.666667 537.6 874.666667 426.698667c0-145.44-80.853333-246.218667-206.88-246.218667z"
                      p-id="1553"
                      data-spm-anchor-id="a313x.7781069.0.i8"
                      class="selected"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="#" class="fa fa-comment-o">
                  <svg
                    t="1660302245826"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1736"
                    data-spm-anchor-id="a313x.7781069.0.i13"
                    width="18"
                    height="18"
                  >
                    <path
                      d="M157.568 751.296c-11.008-18.688-18.219-31.221-21.803-37.91A424.885 424.885 0 0 1 85.333 512c0-235.637 191.03-426.667 426.667-426.667S938.667 276.363 938.667 512 747.637 938.667 512 938.667a424.779 424.779 0 0 1-219.125-60.502A2786.56 2786.56 0 0 0 272.82 866.4l-104.405 28.48c-23.893 6.507-45.803-15.413-39.285-39.296l28.437-104.288z m65.301 3.787l-17.258 63.306 63.306-17.258a32 32 0 0 1 24.523 3.21 4515.84 4515.84 0 0 1 32.352 18.944A360.79 360.79 0 0 0 512 874.667c200.299 0 362.667-162.368 362.667-362.667S712.299 149.333 512 149.333 149.333 311.701 149.333 512c0 60.587 14.848 118.955 42.827 171.136 3.712 6.912 12.928 22.827 27.37 47.232a32 32 0 0 1 3.34 24.715z m145.995-70.774a32 32 0 1 1 40.917-49.205A159.19 159.19 0 0 0 512 672c37.888 0 73.675-13.173 102.187-36.885a32 32 0 0 1 40.917 49.216A223.179 223.179 0 0 1 512 736a223.179 223.179 0 0 1-143.136-51.69z"
                      p-id="1737"
                      data-spm-anchor-id="a313x.7781069.0.i14"
                      class="selected"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div class="data">
            <div class="content">
              <span class="author">澎湃新闻客户端</span>
              <h1 class="title">
                <a href="#"
                  >G.E.M.邓紫棋新专辑第二首《HELL》上线，多变唱腔诠释爱的致命伤</a
                >
              </h1>
              <p class="text">
                G.E.M.邓紫棋新专辑《启示录》第二波主打曲《HELL》MV正式上线，在这首歌中，G.E.M.邓紫棋不仅仅满足于用音乐符号书写爱情里的情意绵绵，而是选择以摇滚编曲与炸裂唱腔呈现在爱情中每个情绪被煽动的瞬间。MV中爆破与车祸场景更是营造犹如置身地狱，被熊熊烈火包围后，让人窒息的氛围，引发歌迷对MV后续故事情节走向的好奇。
              </p>
              <!--<a href="#" class="button">更多</a>-->
              <button class="custom-btn btn-16">了解更多</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
	name: "NewsCardView",
  data() {
    return {
      // 使用 require 动态加载本地图片路径
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/images/bg1.jpg')})`,
        backgroundPosition: '20% 1%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }
    };
  }
};

</script>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #77d7b9;
$white: #fff;
$black: #000;
$open-sans: "Open Sans", sans-serif;

// clear-fix mixin
@mixin cf {
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
}

a {
  text-decoration: none;
}

h3 {
  font-family: $open-sans;
  font-weight: 300;
}

.news-card-box {
  display: flex;
  //align-items: center;
  width: 100%;
  overflow: auto;
  background-color: rgba(16 18 27 / 10%);
  padding: 20px 40px;
  font-family: $open-sans;

  .news-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  @media screen and (max-width: 945px) {
    padding: 20px;
  }
}

// 公共样式
.card {
  //float: left;
  padding: 0px 20px;
  margin: 10px 0;
  width: 50%;

  @media screen and (max-width: 670px) {
    width: 100%;
  }

  .menu-content {
    @include cf;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
    }
    a {
      color: $white;
    }
    span {
      position: absolute;
      left: 50%;
      top: 0;
      font-size: 10px;
      font-weight: 700;
      font-family: "Open Sans";
      transform: translate(-50%, 0);
    }
  }
  .wrapper {
    background-color: $white;
    min-height: 540px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);
    border-radius: 10px;
    &:hover {
      .data {
        transform: translateY(0);
      }
    }
  }
  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(100px + 1em));
    transition: transform 0.3s;
    .content {
      padding: 1em;
      position: relative;
      z-index: 1;
    }
  }
  .author {
    font-size: 12px;
  }
  .title {
    margin-top: 10px;
  }
  .text {
    height: 100px;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:checked + .menu-content {
    transform: translateY(-60px);
  }
}

// 第一个样式
.example-1 {
  .wrapper {
  }
  .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $bermuda;
    color: $white;
    padding: 0.8em;
    span {
      display: block;
      text-align: center;
    }
    .day {
      font-weight: 700;
      font-size: 24px;
      text-shadow: 2px 3px 2px rgba($black, 0.18);
    }
    .month {
      text-transform: uppercase;
    }
    .month,
    .year {
      font-size: 12px;
    }
  }
  .content {
    background-color: $white;
    box-shadow: 0 5px 30px 10px rgba($black, 0.3);
  }
  .title {
    a {
      color: lighten($black, 50%);
    }
  }
  .menu-button {
    position: absolute;
    z-index: 999;
    top: 16px;
    right: 16px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    span {
      width: 5px;
      height: 5px;
      background-color: lighten($black, 50%);
      color: lighten($black, 50%);
      position: relative;
      display: inline-block;
      border-radius: 50%;
      &::after,
      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: currentColor;
        position: absolute;
        border-radius: 50%;
      }
      &::before {
        left: -10px;
      }
      &::after {
        right: -10px;
      }
    }
  }
  .menu-content {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    transition: transform 0.3s;
    transform: translateY(0);
    li {
      width: 33.333333%;
      float: left;
      background-color: $bermuda;
      height: 60px;
      position: relative;
    }
    a {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
    span {
      top: -10px;
    }
  }
}

// 第二个样式
.example-2 {
  .wrapper {
    background: url(https://nimg.ws.126.net/?url=http%3A%2F%2Fdingyue.ws.126.net%2F2022%2F0812%2Ff7b9a2a7j00rghnca000yc000m800etc.jpg&thumbnail=660x2147483647&quality=80&type=jpg)
      center / cover no-repeat;
    &:hover {
      .menu-content {
        span {
          transform: translate(-50%, -10px);
          opacity: 1;
        }
      }
    }
  }
  .header {
    @include cf;
    color: $white;
    padding: 1em;
    width: 100%;

    .date {
      float: left;
      font-size: 12px;
    }
    .menu-content {
      float: right;
      li {
        margin: 0 5px;
      }
    }
  }

  .data {
    color: $white;
    transform: translateY(calc(140px + 4em));
  }
  .title {
    a {
      color: $white;
    }
  }

  .custom-btn {
    //width: 100%;
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    margin: 2em auto 1em;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
    text-align: center;
  }
  .btn-16 {
    border: none;
    color: #ffffff;

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      left: 0;
      direction: rtl;
      z-index: -1;
      box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
      transition: all 0.3s ease;
      border-radius: 5px;
    }
    &:hover {
      color: #ffffff;
    }
    &:hover:after {
      left: auto;
      right: 0;
      width: 100%;
    }
    &:active {
      top: 2px;
    }
  }
}
</style>
