<template>
	<!--父级路由和子级路由的选择-->
	<router-view v-if="$route.path !== '/treasure'"></router-view>
	
	<div class="treasure" v-else>
		<content-wrapper :wrapper="wrapper"></content-wrapper>
		<!-- <line-card :recommend="recommend"></line-card> -->
		<block-card :products="products"></block-card>
	</div>
</template>

<script>
import ContentWrapper from '@/components/treasure/ContentWrapper.vue';
//import LineCard from '@/components/treasure/LineCard.vue';
import BlockCard from '@/components/treasure/BlockCard.vue';

export default {
	// components: {ContentWrapper, LineCard, BlockCard},
	components: {ContentWrapper, BlockCard},
	data(){
		return {
			wrapper:[
				{image:require('.././assets/images/gallery.png'),name:'AI纪元',content:'在科技日新月异的今天，我们正逐步迈入一个由人工智能（AI）深刻塑造的新时代——“AI纪元”',url:''},
			
			],
			products:[
				{id:'010',image:require('.././assets/images/photo_wall.png'),name:'12月AI大事件',recommend:' ',url:''},
				{id:'007',image:require('.././assets/images/gallery.png'),name:'OpenAI 12天新品发布',recommend:'OpenAI 12天新品发布，个人感觉比较重要的几个有：发布正式版ChatGPT-o1、Sora正式发布、Canvas面向所有用户免费使用、新增Projects功能可高效管理会话、ChatGPT Search面向所有用户开放、推出O3模型',url:''},
				{id:'018',image:require('.././assets/images/time_table.png'),name:'开源最新系列模型',recommend:'DeepSeek开源最新系列模型DeepSeek-V3（671B的MOE大模型），综合评估表明，现在是最强的开源大模型，据说训练规模不到600万美元',url:''},
				{id:'004',image:require('.././assets/images/change_skin.png'),name:'AI编程',recommend:'AI编程助手类产品，Cursor再融一亿美元，估值达26亿美元；Github Copilot则免费版上线，但是有限制每月补全2000次；Devin正式上线，可独立开发和修复bug',url:''},
				{id:'005',image:require('.././assets/images/input_skin.png'),name:'英伟达',recommend:'英伟达发布全新GPU GB300和B300，推理性能迎巨大飞跃',url:''},
				{id:'007',image:require('.././assets/images/gallery.png'),name:'AI投资',recommend:'字节跳动2024年AI投资800亿元，接近BAT总和',url:''},
				{id:'018',image:require('.././assets/images/time_table.png'),name:'谷歌升级AI',recommend:'谷歌升级AI视频生成模型Veo2，4K分辨率、人类偏好评分优于Sora，总算硬气了一次，反而Sora的正式推出，并未引起多大的市场效应',url:''},
				{id:'004',image:require('.././assets/images/change_skin.png'),name:'Gemini2.0 Flash AI模型',recommend:'谷歌发布Gemini2.0 Flash AI模型，处理速度比前代产品Gemini 1.5Pro快两倍，并扩展了多模态功能',url:''},
	
			]
			
			
		}
	}
}
</script>

<style lang="scss" scoped>

.treasure {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	color: #fff;
	padding: 20px 40px;
	height: 100%;
	overflow: auto;
	background-color: rgba(16 18 27 / 10%);
	@media screen and (max-width: 510px) {
		padding: 20px;
	}
	
}


</style>

