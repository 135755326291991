<template>
  <div class="book-container">
    <div class="book" :class="{ flipping: isFlipping }">
      <!-- 封面 -->
      <div
        v-if="currentPage === 0"
        class="page cover front"
        @click="nextPage"
        :style="getCoverStyle"
      >
        <span>轻触打开人格之书</span>
      </div>

      <!-- 左右两页内页 -->
      <div
        v-for="(page, index) in visiblePages"
        :key="index"
        class="page content"
      >
        <!-- 左页 -->
        <div
          class="page-left"
          :style="getPageStyle(index, 'left', page.leftImage)"
          @click="prevPage"
        >
          <span>{{ page.left }}</span>
        </div>

        <!-- 书脊 -->
        <div class="book-spine"></div>

        <!-- 右页 -->
        <div
          class="page-right"
          :style="getPageStyle(index, 'right', page.rightImage)"
          @click="nextPage"
        >
          <span>{{ page.right }}</span>
        </div>
      </div>

      <!-- 尾页 -->
      <div v-if="currentPage === totalPages" class="page cover">
        <span>封底</span>
      </div>
    </div>

    <!-- 翻页按钮 -->
    <div v-if="currentPage > 0 && currentPage < totalPages" class="controls">
      <button @click="prevPage">上一页</button>
      <button @click="nextPage">下一页</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 0, // 当前页码
      totalPages: 6, // 总页数（不包括封面和封底）
      isFlipping: false, // 翻页动画状态
      coverImage: require("@/assets/background-image.png"), // 封面背景图
      roles: [
        {
          name: "INTP（逻辑学家）",
          description: "理性、独立、好奇",
          background:
            "科学家，致力于破解自然的奥秘，庄园主经常提出不合逻辑的要求。",
          motivation: "对于庄园主的独裁式管理和做事的方式非常反感。",
        },
        {
          name: "ESTP（企业家）",
          description: "行动导向、果敢、富有魅力",
          background: "生意人，庄园主的合作伙伴，擅长快速决策并采取行动。",
          motivation:
            "庄园主的行为可能威胁到自己的利益，甚至在某些决策上产生冲突。",
        },
        {
          name: "ESFP（表演者）",
          description: "热情、社交、感性",
          background: "演员，庄园主曾为她提供资金支持，但同时也有过很多控制。",
          motivation: "想要摆脱庄园主的控制，寻找自己的自由。",
        },
        {
          name: "ENFP（激励者）",
          description: "充满创意、热情、乐观",
          background: "创新型企业家，庄园主的保守做法让她无法发挥自己的创意。",
          motivation: "与庄园主的矛盾加剧，可能以非常情绪化的方式表达不满。",
        },
        {
          name: "ENTP（辩论家）",
          description: "富有创造力、机智、喜欢挑战",
          background: "商界新锐，擅长辩论，喜欢挑战传统观念。",
          motivation: "与庄园主的理念有严重分歧，可能对他采取行动。",
        },
        {
          name: "ESTJ（执行者）",
          description: "果断、组织、领导",
          background: "退役军官，做事严谨有序，要求别人也遵守严格的规则。",
          motivation: "庄园主曾挑战他的权威，可能对他感到威胁。",
        },
        {
          name: "ESFJ（提供者）",
          description: "关怀、合作、组织能力强",
          background: "社交名媛，庄园主邀请她来是为了提升庄园的社交地位。",
          motivation: "对庄园主的一些行为感到不满，可能出于某种社会压力。",
        },
        {
          name: "ENFJ（主人公）",
          description: "领导力、亲和力、理想主义",
          background: "知名政治家，庄园主曾提议与她进行合作，但她拒绝了。",
          motivation: "为了自己的信念，与庄园主的矛盾逐渐加深。",
        },
        {
          name: "ENTJ（指挥官）",
          description: "果断、领导力强、战略眼光",
          background: "大企业CEO，庄园主曾在合作中做出一些不合理的决策。",
          motivation: "可能出于职业竞争的压力而与庄园主发生冲突。",
        },
      ],
    };
  },
  computed: {
    visiblePages() {
      if (this.currentPage === 0 || this.currentPage > this.totalPages) {
        return [];
      }

      // 如果是最后一页，显示封底
      if (this.currentPage === this.totalPages) {
        return [
          {
            left: "封底",
            right: "", // 没有右页内容
            leftImage: require("@/assets/images/renge/INFJ.jpeg"), // 封底的图片
            rightImage: require("@/assets/images/renge/INFP.jpeg"), // 空的右页图片
          },
        ];
      }

      const startIndex = (this.currentPage - 1) * 2 + 1; // 计算页码
      const leftRoleIndex = startIndex;
      const rightRoleIndex = startIndex + 1;

      return [
        {
          left: this.roles[leftRoleIndex]
            ? `${this.roles[leftRoleIndex].name}: ${this.roles[leftRoleIndex].description}`
            : "",
          right: this.roles[rightRoleIndex]
            ? `${this.roles[rightRoleIndex].name}: ${this.roles[rightRoleIndex].description}`
            : "",
          leftImage:
            startIndex === 1
              ? require("@/assets/images/renge/INFJ.jpeg")
              : startIndex === 2
              ? require("@/assets/images/renge/INFP.jpeg")
              : startIndex === 3
              ? require("@/assets/images/renge/INTJ.jpeg")
              : startIndex === 4
              ? require("@/assets/images/renge/ISFJ.jpeg")
              : require("@/assets/images/cat.jpg"),


          rightImage:
            startIndex === 1
              ? require("@/assets/images/renge/ISFP.jpeg")
              : startIndex === 2
              ? require("@/assets/images/renge/ISTJ.jpeg")
              : startIndex === 3
              ? require("@/assets/images/renge/ISTP.jpeg")
              : startIndex === 4
              ? require("@/assets/images/dog.jpg")
              : require("@/assets/images/cat.jpg"),
        },
      ];
    },
  },
  methods: {
    getCoverStyle() {
      return {
        backgroundImage: `url(${this.coverImage})`,
        backgroundSize: "cover", // 背景图覆盖整个区域
        backgroundPosition: "center", // 背景图居中显示
      };
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.flipDirection = "next";

        this.startFlipping();
        setTimeout(() => {
          this.currentPage++;
          this.endFlipping();
        }, 800); // 动画时间（800ms）
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.flipDirection = "prev";

        this.startFlipping();
        setTimeout(() => {
          this.currentPage--;
          this.endFlipping();
        }, 800);
      }
    },
    startFlipping() {
      this.isFlipping = true; // 开始翻页动画
    },
    endFlipping() {
      this.isFlipping = false; // 结束翻页动画
    },
    getPageStyle(index, side, image) {
      const isLeft = side === "left";
      const isRight = side === "right"; // 判断是否为右页

      const angle = this.isFlipping
        ? isLeft
          ? this.flipDirection === "prev"
            ? 180
            : 0 // 上一页时，左页旋转180度
          : this.flipDirection === "next"
          ? -180
          : 0 // 下一页时，右页旋转-180度
        : 0;
      // eslint-disable-next-line
      const translateValue = isLeft ? "-100%" : "100%";

      const zIndex = isLeft
        ? this.flipDirection === "prev" && angle === 180
          ? 3
          : 2 // 左页往右翻时，层级设为 3
        : isRight
        ? this.flipDirection === "next" && angle === -180
          ? 3
          : 2 // 右页往左翻时，层级设为 3
        : 1; // 默认设置 z-index 为 1

      return {
        transform: isLeft
          ? `rotateY(${angle}deg) ` // 左页旋转并平移
          : `rotateY(${angle}deg) `, // 右页翻转并平移
        zIndex: zIndex,

        transition: this.isFlipping ? "transform 1.8s ease" : "none", // 控制翻页时的过渡效果

        backgroundImage: `url(${image})`, // 设置背景图
        backgroundSize: "cover", // 背景图覆盖整个区域
        backgroundPosition: "center", // 背景图居中
      };
    },
  },
};
</script>

<style scoped>
/* 书本容器样式 */
.book-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #ca83fa;
  width: 100%;
  position: relative; /* 相对定位，供子元素绝对定位使用 */
}

/* 书本整体样式 */
.book {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1500px; /* 创建3D透视效果 */
  transform-style: preserve-3d; /* 确保元素在3D空间中进行转换 */
}

/* 单页容器样式 */
.page.content {
  position: relative; /* 作为子元素的定位上下文 */
  width: 100%; /* 根据需求设置容器宽度 */
  height: 100%; /* 根据需求设置容器高度 */
  margin: 0 auto; /* 居中对齐容器 */
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background: #f5f5f5; /* 背景色，便于调试 */
}

/* 单页通用样式 */
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  backface-visibility: hidden; /* 防止翻转时显示背面内容 */
  transition: transform 1.8s ease; /* 控制翻页的动画效果 */
  display: flex;
  justify-content: right;
  align-items: center;
}

/* 左页的样式 */
.page-left {
  left: 0%;
  position: absolute;
  width: 49.8%;
  height: 100%; /* 高度占满父容器 */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #333;
  overflow: hidden;
  border: 1px solid #1976d2; /* 页面的边框 */
  transform-origin: right center; /* 设置左页的翻转原点为书脊中心 */
  transition: transform 1.8s ease;
}

.page-left {
  left: 0%;
  position: absolute;
  width: 49.8%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* 垂直方向上靠下 */
  background-color: #fff;
  color: #333;
  overflow: hidden;
  border: 1px solid #1976d2; /* 页面的边框 */
  transform-origin: right center; /* 设置左页的翻转原点为书脊中心 */
  transition: transform 1.8s ease;
  font-size: 2rem;
  font-weight: bold;
}

.page-left span {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgb(149, 237, 248),
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  ); /* 彩虹色渐变背景 */
  background-clip: text; /* 让背景色应用到文字上 */
  color: transparent; /* 文字本身颜色透明 */
  text-shadow: 0 0 5px rgba(196, 245, 202, 0.8),
    0 0 10px rgba(238, 240, 123, 0.6), 0 0 15px rgba(107, 154, 255, 0.4),
    0 0 20px rgba(209, 120, 180, 0.2), 0 0 25px rgba(255, 0, 0, 0.1); /* 为文字增加红色阴影 */
  animation: rainbowText 3s infinite linear; /* 动画效果 */
}

/*  右页的样式 */

.page-right {
  left: 50.2%;
  position: absolute;
  width: 49.8%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* 垂直方向上靠下 */
  background-color: #fff;
  color: #333;
  overflow: hidden;
  border: 1px solid #1976d2; /* 页面的边框 */
  transform-origin: left center; /* 设置右页的翻转原点为书脊中心 */
  transition: transform 1.8s ease;
}

.page-right span {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgb(149, 237, 248),
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  ); /* 彩虹色渐变背景 */
  background-clip: text; /* 让背景色应用到文字上 */
  color: transparent; /* 文字本身颜色透明 */
  text-shadow: 0 0 5px rgba(196, 245, 202, 0.8),
    0 0 10px rgba(238, 240, 123, 0.6), 0 0 15px rgba(107, 154, 255, 0.4),
    0 0 20px rgba(209, 120, 180, 0.2), 0 0 25px rgba(255, 0, 0, 0.1); /* 为文字增加红色阴影 */
  animation: rainbowText 3s infinite linear; /* 动画效果 */
}

/* 书脊样式 */
.book-spine {
  position: absolute;
  width: 1%;
  height: 100%;
  background: #333;
  justify-content: center;
  align-items: center;
  transform: translateX(-10%);
}

/* 封面样式 */
.page.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(@/assets/images/renge/1.png) center/cover no-repeat; /* 设置背景图 */
  color: #fff;
}

.page.cover span {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgb(224, 234, 235),
    rgb(136, 136, 136),
    rgb(59, 59, 44),
    rgb(30, 37, 30),
    rgb(146, 146, 146),
    rgb(46, 46, 63),
    rgb(54, 42, 54)
  ); /* 彩虹色渐变背景 */
  background-clip: text; /* 让背景色应用到文字上 */
  color: transparent; /* 文字本身颜色透明 */
  text-shadow: 0 0 5px rgba(196, 245, 202, 0.8),
    0 0 10px rgba(238, 240, 123, 0.6), 0 0 15px rgba(107, 154, 255, 0.4),
    0 0 20px rgba(209, 120, 180, 0.2), 0 0 25px rgba(255, 0, 0, 0.1); /* 为文字增加红色阴影 */
  animation: rainbowText 3s infinite linear; /* 动画效果 */
}


/* 封底样式 */
.page.cover.back {
  left: 50%;
  background: #555;
  color: #fff;
  border: 1px solid #ff0202; /* 页面的边框 */
  position: absolute; /* 绝对定位，确保它覆盖整个容器 */
  top: 0;
  left: 0;
  width: 100%; /* 与 .page.content 宽度一致 */
  height: 100%; /* 与 .page.content 高度一致 */
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden; /* 防止翻转时显示背面内容 */
  z-index: -1; /* 保证封底在所有页面下方 */
}

/* 翻页控制按钮样式 */
.controls {
  margin-top: 20px;
}

button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background: #e167f1;
  color: #fff;
  cursor: pointer;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
</style>
